<template>
  <RegistrationComponent></RegistrationComponent>
</template>

<script>
import RegistrationComponent from "@/components/signup/RegistrationComponent.vue";

export default {
  name: "Registration",
  components: {
    RegistrationComponent
  }
};
</script>
