import { $http } from "@/main";
import { Cookie } from "@/common/cookie";
import { DomainAddModel } from "@/models/DomainAddModel";

export class FusionService {
  private readonly token: string = "";
  constructor() {
    $http.defaults.baseURL = process.env.VUE_APP_FUSION_HOST;
    this.token = Cookie.get("AUTH");
  }
  public async post(model: DomainAddModel) {
    return $http.post("/domain", model, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async createDomain(domain: string) {
    return $http.post(
      `/mobile/domain`,
      { domainName: domain },
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
    );
  }
}
