
import vue from "vue";
import SpinnerComponent from "@/components/custom/spinnerLoaderComponent.vue";
import HeaderComponent from "@/components/ui/HeaderComponent.vue";
import CopyrightComponent from "@/components/custom/CopyrightComponent.vue";
import { notification } from "onsenui";
export default vue.extend({
  components: {
    SpinnerComponent,
    HeaderComponent,
    CopyrightComponent
  },
  data() {
    return {
      loadingText: "",
      isLoading: false,
      isValidating: false,
      isVisible: false,
      model: {
        username: "",
        password: ""
      }
    };
  },
  mounted() {
    notification.toast(
      "IMPORTANT: We recommend using Google Chrome to fully use this service. Ignore if you're already on Chrome",
      {
        animation: "fall",
        buttonLabel: "OK"
      }
    );
  },
  methods: {
    signIn() {
      // @ts-ignore
      const { provisioningScheme, cid } = WebRTCApp;
      console.log(provisioningScheme);
      console.log(cid);
      const uri =
        provisioningScheme +
        ":" +
        this.model.username +
        ":" +
        this.model.password +
        "@" +
        cid;
      // @ts-ignore
      window.WebRTCApp.signin(uri);
      /* if (this.model.username && this.model.password) {
        const url = `csc:${this.model.username}:${this.model.password}@pbxservice.cloud`;
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.click();
      }*/
    }
  },
  watch: {
    "model.password": function() {
      /** LAME!! */
      if (this.model.username && this.model.password) {
        document.querySelector(".btn-sign-in").classList.remove("btn-dark");
      } else {
        document.querySelector(".btn-sign-in").classList.add("btn-dark");
      }
    }
  }
});
