
import Vue from "vue";
import HeaderComponent from "@/components/ui/HeaderComponent.vue";
import SpinnerComponent from "@/components/custom/spinnerLoaderComponent.vue";

import { AuthService, SignUpModel } from "@/services/auth.service";
import { CustomerService } from "@/services/customer.service";
import { Cookie } from "@/common/cookie";
import { SeverityTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/severity.lookup";
import { LoggingTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/logging.lookup";
import { ErrorMeta } from "@/models/ErrorMeta";
import { $logger } from "@/main";

export default Vue.extend({
  components: {
    HeaderComponent,
    SpinnerComponent
  },
  data() {
    return {
      telProps: {
        mode: "international",
        defaultCountry: "ZA",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Phone Number",
        required: true,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: [],
        onlyCountries: ["BW", "LS", "NA", "ZA", "MW", "MZ", "SZ", "ZM", "ZW"],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 20,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      },
      model: {} as SignUpModel,
      isUserCreated: false,
      isLoading: false,
      loadingText: "",
      errorMessage: "",
      oneTimePin: "",
      errors: Array<string>(),
      promo: {
        promo_code: null,
        reseller_customer_id: null
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please Provide an Email Address",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    /* clean before signing up*/
    Cookie.remove("AUTH");
  },
  methods: {
    async signUserUp() {
      try {
        if (!this.isModelValid() || this.errors.length > 0) {
          return;
        }
        this.isLoading = true;
        this.loadingText = "Please Wait";
        if (!this.model.email) {
          /* generate random email */
          this.model.email = this.generateEmail();
          /* require an email if the promo code is not from AVON*/
          if (!this.model.email) {
            this.isLoading = false;
            return;
          }
        }
        this.model.phoneNumber = this.model.phoneNumber.replace(/\s/g, "");
        if (this.model.phoneNumber.length < 12) {
          this.isLoading = false;
          (this as any).$ons.notification.toast(
            `WARNING: ${this.model.phoneNumber} is an invalid phone number`,
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
          return;
        }
        this.model.email = this.model.email.trim();
        const result = await new AuthService().signUp(this.model);
        if (result.data && result.status === 200) {
          Cookie.parseToken(result.data.token);
          Cookie.set("AUTH", result.data.token, 1);
          (this as any).$ons.notification.toast(result.data.message, {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
          await this.$router.push("otp/" + this.model.phoneNumber);
        } else {
          (this as any).$ons.notification.toast("ERROR: Sign up failed!", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        }
        this.isLoading = false;
      } catch (exception) {
        this.isLoading = false;
        const err = exception.response.data;
        if (err && err.message) {
          (this as any).$ons.notification.toast(
            `ERROR: ${exception.response.data.message}`,
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
        } else {
          (this as any).$ons.notification.toast(
            "ERROR: And unknown error has occurred",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
        }
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "signUserUp()",
          error: exception.response.data
        } as ErrorMeta;
        $logger.log(
          exception,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    },
    async checkPromoCode() {
      try {
        this.promo = {};
        const result = await new CustomerService().getPromoCode(
          this.model.referralCode
        );
        if (result.data && result.data.found) {
          this.promo = result.data;
          (this as any).$ons.notification.toast("Promo code applied!", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        } else {
          (this as any).$ons.notification.toast("WARNING: Invalid promo code", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        }
      } catch (e) {
        this.isLoading = false;
        (this as any).$ons.notification.toast(`ERROR: ${e.message}`, {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
      }
    },
    passwordValidator() {
      const password = this.model.password;
      this.errors = [];
      if (!/([0-9])+/.test(password)) {
        this.errors.push("Password should have at least 1 number");
        return false;
      }
      if (!/([a-z])+/.test(password)) {
        this.errors.push("Password should have at least 1 lower case letter");
        return false;
      }
      if (!/([A-Z])+/.test(password)) {
        this.errors.push("Password should have at least 1 upper case letter");
        return false;
      }
      if (/([\@#$\&\*]){1,}/.test(password)) {
        this.errors.push("Password contains illegal characters @#$&*");
        return false;
      }
      if (!/([\!%\^&\*\(\)_\-\.])+/.test(password)) {
        this.errors.push("Password should have at least 1 symbol <>.!%^()_-");
        return false;
      }
      return true;
    },
    formatPhoneNumber() {
      this.errors = [];
      if (!/[0-9]/.test(this.model.phoneNumber)) {
        this.errors.push("Phone number must be like +27712345678");
        return false;
      }
      if (`${this.model.phoneNumber}`.indexOf("0") === 0) {
        this.model.phoneNumber = `+27${this.model.phoneNumber.substr(1)}`;
        return this.model.phoneNumber.length === 12;
      }
      if (this.model.phoneNumber && this.model.phoneNumber.length < 12) {
        this.errors.push("Phone number must be 12 characters");
        return false;
      }
    },
    validateEmail() {
      this.errors = [];
      const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!re.test(this.model.email)) {
        this.errors.push("Invalid email format");
        return false;
      }
      return true;
    },
    isModelValid() {
      if (
        !this.model.lastName ||
        !this.model.phoneNumber ||
        !this.model.password ||
        !this.model.firstName
      ) {
        this.errors.push("Please fill in all the required fields");
        return false;
      }
      return true;
    },
    validateFullname() {
      this.errors = [];
      if (
        !/^([A-Za-z])/.test(this.model.lastName) ||
        !/^([A-Za-z])/.test(this.model.firstName)
      ) {
        this.errors.push(`First name and last name are required`);
        return false;
      }
      if (
        /([\@#$%()\^&\*]){1,}/.test(this.model.lastName) ||
        /([\@#$%()\^&\*]){1,}/.test(this.model.firstName)
      ) {
        this.errors.push(
          "First or last name cannot have special characters ( @#$&*% )"
        );
        return false;
      }
    },
    generateEmail(): string {
      let fullName = `${this.model.firstName}${this.model.lastName}`;
      fullName = fullName.replace(/\s/g, "").toLocaleLowerCase();
      const maximum = 15;
      const minimum = 10;
      const length =
        Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
      const charset = "abcdefghijklmnopqrstuvwxyz";
      let result = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        result += charset.charAt(Math.floor(Math.random() * n));
      }
      if (
        this.promo.promo_code &&
        this.promo.promo_code.includes("AVONJUSTINEVIP")
      ) {
        return `${fullName}.${result}avon@mytelnet.co.za`;
      } else if (this.model.referralCode) {
        (this as any).$ons.notification.toast(
          "WARNING: An email is required based of this promo code",
          {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          }
        );
        return "";
      }
    },
    gotoSignIn() {
      this.$router.push("sign-in");
    },
    goBack() {
      this.$router.push("welcome");
    },
    async login() {
      await this.$router.push("login");
    }
  },
  watch: {
    "model.firstName": function() {
      this.validateFullname();
    },
    "model.lastName": function() {
      this.validateFullname();
    },
    "model.password": function() {
      this.passwordValidator();
    },
    "model.email": function() {
      this.validateEmail();
    },
    "model.oneTimePin": function() {
      console.log(typeof this.model.oneTimePin);
    },
    "model.referralCode": function() {
      this.model.referralCode = this.model.referralCode.toUpperCase();
      this.model.referralCode = this.model.referralCode.replace(/\s/g, "");
    }
  }
});
