
import Vue from "vue";
import SpinnerLoader from "@/components/custom/spinnerLoaderComponent.vue";
import { Cookie } from "@/common/cookie";
import { FusionService } from "@/services/fusion.service";
import { CampaignService } from "@/services/campaign.service";
import { AuthService, SendPBXDetail } from "@/services/auth.service";
import { BillingService } from "@/services/billing.service";
import { ErrorMeta } from "@/models/ErrorMeta";
import { $logger } from "@/main";
import { SeverityTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/severity.lookup";
import { LoggingTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/logging.lookup";
import { DomainAddModel } from "@/models/DomainAddModel";

export default Vue.extend({
  components: { SpinnerLoader },
  data() {
    return {
      isLoading: true,
      loadingText: "Please wait",
      pbxSuffix: ".pbxservice.cloud",
      isCheckingNameAvailability: false,
      domainAvailability: true,
      errorMessage: null,
      customerHasActiveDomain: false,
      domainUuid: null,
      hasDeal: false,
      hasDomain: false,
      fusionUserUuid: null,
      model: {
        domain: ""
      }
    };
  },
  async mounted() {
    await this.createPBX();
    this.isLoading = false;
  },
  methods: {
    autoGeneratedHostName(): string {
      const cookie = Cookie.get("AUTH");
      const token = Cookie.parseToken(cookie);
      return `${token.customerId}`;
    },
    async getCurrentCampaignProducts() {
      try {
        const campaignSetProductsResult = await new BillingService().getCampaignProducts();
        return campaignSetProductsResult.status === 200;
      } catch (exception) {
        this.isLoading = false;
        this.errorMessage = exception.response.data.message;
        (this as any).$ons.notification.toast(this.errorMessage, {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "getCurrentCampaignProducts()",
          error: exception.response.data
        } as ErrorMeta;
        $logger.log(
          exception,
          JSON.stringify(meta),
          SeverityTypeLookUp.medium,
          LoggingTypeLookUp.error
        );
        return false;
      }
    },
    async createPBX() {
      await this.customerHasDeal();
      if (!this.hasDeal) {
        await this.getCurrentCampaignProducts();
      }
      this.model.domain = this.autoGeneratedHostName();
      try {
        this.isLoading = true;
        this.errorMessage = "";
        if (!this.customerHasActiveDomain) {
          this.loadingText = "Setting Up Your Voice Service";
          const request: DomainAddModel = {
            domainName: this.model.domain.replace(this.pbxSuffix, "")
          };
          const result = await new FusionService().post(request);
          if (result.status === 201 || result.status === 200) {
            await this.sendPBXInfo(result.data);
            let userDomain = `${result.data.domain}.pbxservice.cloud`;
            const config = `csc:${result.data.username}@${userDomain}:${result.data.password}@pbxservice.cloud`;
            await this.$router.push({
              name: "start-app",
              params: {
                authcsc: config
              }
            });
          } else {
            throw "error creating domain";
          }
        }
        this.isLoading = false;
      } catch (exception) {
        this.isLoading = false;
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "createPBX()",
          error: exception.response.data
        } as ErrorMeta;
        $logger.log(
          exception,
          JSON.stringify(meta),
          SeverityTypeLookUp.high,
          LoggingTypeLookUp.error
        );
        if (!exception.response) {
          this.errorMessage = "server unavailable";
        } else {
          this.errorMessage = exception.response.data.message;
        }
        this.$notify({
          title: "Error",
          message: this.errorMessage,
          type: "error",
          offset: 0,
          duration: 9000
        });
      }
    },
    async customerHasDeal() {
      try {
        const result = await new BillingService().customerHasDeal();
        this.hasDeal = result.data != 0;
      } catch (exception) {
        this.isLoading = false;
        this.errorMessage = exception.response.data.message;
        this.errorMessage = "error getting a sales order";
        (this as any).$ons.notification.toast("error getting a sales order", {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "customerHasDeal()",
          error: exception.response.data
        } as ErrorMeta;
        $logger.log(
          exception,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    },
    async sendPBXInfo(info: SendPBXDetail) {
      try {
        await new AuthService().sendPBXInformation(info);
      } catch (e) {
        this.isLoading = false;
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "sendPBXInfo()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    }
  }
});
