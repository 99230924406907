import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tmsid: 0,
    userAuthToken: "",
    fusionToken: "",
    billingToken: "",
    cerebroToken: "",
    clientId: "",
    resellerId: "uc886sob9vSqiOL4AF3Z93HfFgN2",
    serviceName: "auth-manager",
    currentUser: {},
    tokenRequestData: {}
  },
  getters: {
    getFusionToken: state => {
      return state.fusionToken || window.localStorage.getItem("FusionToken");
    },
    getBillingToken: state => {
      return state.billingToken || window.localStorage.getItem("BillingToken");
    },
    getCerebroToken: state => {
      return state.cerebroToken || window.localStorage.getItem("CerebroToken");
    },
    getUserAuthToken: state => {
      return state.userAuthToken;
    },
    getResellerId: state => {
      return state.resellerId;
    },
    getClientId: state => {
      return state.clientId;
    },
    getServiceName: state => {
      return state.serviceName;
    },
    getTokenRequestData: state => {
      return state.tokenRequestData;
    },
    getTMSID: state => {
      return state.tmsid || window.localStorage.getItem("TMSID");
    }
  },
  mutations: {
    setTMSID: (state, payload) => {
      state.tmsid = payload;
      window.localStorage.setItem("TMSID", payload);
    },
    setCurrentUser: (state, payload) => {
      state.currentUser = payload;
    },
    setUserAuthToken: (state, payload) => {
      state.userAuthToken = payload;
    },
    setFusionToken: (state, payload) => {
      state.fusionToken = payload;
      window.localStorage.setItem("FusionToken", payload);
    },
    setBillingToken: (state, payload) => {
      state.billingToken = payload;
      window.localStorage.setItem("BillingToken", payload);
    },
    setCerebroToken: (state, payload) => {
      state.cerebroToken = payload;
      window.localStorage.setItem("CerebroToken", payload);
    },
    setClientId: (state, payload) => {
      state.clientId = payload;
    },
    setTokenRequestData: (state, payload) => {
      state.tokenRequestData = payload;
    }
  },
  actions: {
    setCurrentUser: ({ commit }, currentUser: any) => {
      commit("setCurrentUser", currentUser);
    },
    setUserAuthToken: ({ commit }, authToken: string) => {
      commit("setUserAuthToken", authToken);
    },
    setClientId: ({ commit }, clientId: string) => {
      commit("setClientId", clientId);
    },
    setFusionToken: ({ commit }, fusionToken: string) => {
      commit("setFusionToken", fusionToken);
    },
    setBillingToken: ({ commit }, billingToken: string) => {
      commit("setBillingToken", billingToken);
    },
    setCerebroToken: ({ commit }, cerebroToken: string) => {
      commit("setCerebroToken", cerebroToken);
    },
    setTokenRequestData: ({ commit }, data: any) => {
      commit("setTokenRequestData", data);
    },
    setTMSID: ({ commit }, tmsid: any) => {
      commit("setTMSID", tmsid);
    }
  },
  modules: {}
});
