import { $http } from "@/main";
import { Cookie } from "@/common/cookie";

export class CampaignService {
  private readonly token: string = "";
  constructor() {
    $http.defaults.baseURL = process.env.VUE_APP_AUTH_HOST;
    this.token = Cookie.get("AUTH");
  }

  public async post(step: number) {
    return $http.post(
      `/campaign`,
      { step: step },
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
    );
  }
  public async get() {
    return $http.get(`/campaign`, {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    });
  }
}
