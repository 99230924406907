import { $http } from "@/main";
import { Cookie } from "@/common/cookie";

export class CustomerService {
  private readonly token: string = "";
  constructor() {
    $http.defaults.baseURL = process.env.VUE_APP_AUTH_HOST;
    this.token = Cookie.get("AUTH");
  }
  public async getPromoCode(referralCode: string) {
    return $http.get(`/customer/promo-code/${referralCode}`);
  }
}
