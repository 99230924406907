<template>
  <CardComponent></CardComponent>
</template>

<script>
import Vue from "vue";
import CardComponent from "@/components/signup/CardComponent.vue";

export default Vue.extend({
  name: "Payments",
  components: {
    CardComponent
  }
});
</script>
