import { $http } from "@/main";
import { Cookie } from "@/common/cookie";
export class AuthService {
  private readonly token: string = "";
  constructor() {
    $http.defaults.baseURL = process.env.VUE_APP_AUTH_HOST;
    this.token = Cookie.get("AUTH");
  }
  public async signUp(model: SignUpModel) {
    return $http.post("/mobile/sign-up", model);
  }
  public async login(model: LoginModel) {
    return $http.post("/mobile/login", model);
  }
  public async createCustomer(customer: AddCustomerModel) {
    return $http.post("/auth/customer", customer);
  }
  public async sendOTP() {
    return $http.get("/user/verification/phone/true", {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async verifyOTP(otp: string) {
    return $http.post(
      "/mobile/verify-phone-number",
      { otp: otp },
      {
        headers: { Authorization: `Bearer ${this.token}` }
      }
    );
  }
  public async forgotPassword(phoneNumber: string) {
    return $http.post(`/mobile/forgot-password`, {
      phoneNumber: phoneNumber
    });
  }
  public async resetPassword(model: any) {
    return $http.post(`/mobile/reset-password`, model);
  }
  public async getCurrentUser() {
    return $http.get("/user/current-user", {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async updatePhone(phoneNumber: string) {
    return $http.patch(
      `/mobile/verification/phone`,
      { phoneNumber: phoneNumber },
      {
        headers: { Authorization: `Bearer ${this.token}` }
      }
    );
  }
  public async getVerificationStatus() {
    return $http.get("/user/verification", {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async refreshToken() {
    return $http.get("/auth/refresh", {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async sendPBXInformation(model: SendPBXDetail) {
    return $http.post("/mobile/sms-pbx-info", model, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async linkUserToFusion(userUuid: any) {
    return $http.post(
      "/mobile/voice-user-link",
      { fusionUserUuid: userUuid },
      {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
    );
  }
  public async getCampaignAuth() {
    return $http.get(`/campaign/auth`, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
}
export interface SignUpModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  referralCode: string;
  // memberShipNumber: string;
}
export interface AddCustomerModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}
export interface LoginModel {
  email: string;
  password: string;
}
export interface SendPBXDetail {
  domainUuid: string;
  extensionNumber: string;
  defaultNumber: string;
  domain: string;
  username: string;
  password: string;
}
