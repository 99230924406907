
import vue from "vue";
import { ErrorMeta } from "@/models/ErrorMeta";
import { $logger } from "@/main";
import { SeverityTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/severity.lookup";
import { LoggingTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/logging.lookup";
import { AuthService } from "@/services/auth.service";
export default vue.extend({
  components: {
    copyright: () => import("@/components/custom/CopyrightComponent.vue"),
    spinner: () => import("@/components/custom/spinnerLoaderComponent.vue"),
    "mtl-header": () => import("@/components/ui/HeaderComponent.vue")
  },
  data() {
    return {
      telProps: {
        mode: "international",
        defaultCountry: "ZA",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Phone Number",
        required: true,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: [],
        onlyCountries: ["BW", "LS", "NA", "ZA", "MW", "MZ", "SZ", "ZM", "ZW"],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 20,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      },
      errors: Array<string>(),
      isLoading: false,
      loadingText: "Please wait",
      showOtp: false,
      model: {
        phoneNumber: null,
        otp: null,
        password: null
      }
    };
  },
  methods: {
    async confirmOtp() {
      try {
        if (
          !this.model.phoneNumber ||
          !this.model.otp ||
          !this.model.password
        ) {
          (this as any).$ons.notification.toast(
            "Please fill in all the fields",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
          return;
        }
        if (this.model.password.length < 8) {
          (this as any).$ons.notification.toast(
            "Password must at least be 8 characters long",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
          return;
        }
        this.isLoading = true;
        const result = await new AuthService().resetPassword(this.model);
        if (result.status == 200) {
          (this as any).$ons.notification.toast(result.data.message, {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
          await this.$router.push("welcome");
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const msg = e.response.data.error || "Failed to verify OTP";
        (this as any).$ons.notification.toast(msg, {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "sendOtp()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.medium,
          LoggingTypeLookUp.error
        );
      }
    },
    async sendOtp() {
      try {
        this.isLoading = true;
        this.model.phoneNumber = this.model.phoneNumber.replace(/\s/g, "");
        const result = await new AuthService().forgotPassword(
          this.model.phoneNumber
        );
        if (result.status == 200) {
          (this as any).$ons.notification.toast(result.data.message, {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
          this.showOtp = true;
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const msg = e.response.data.error || "Failed to send OTP";
        (this as any).$ons.notification.toast(msg, {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "sendOtp()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.medium,
          LoggingTypeLookUp.error
        );
      }
    },
    passwordValidator() {
      const password = this.model.password;
      this.errors = [];
      if (!/([0-9])+/.test(password)) {
        this.errors.push("Password should have at least 1 number");
        return false;
      }
      if (!/([a-z])+/.test(password)) {
        this.errors.push("Password should have at least 1 lower case letter");
        return false;
      }
      if (!/([A-Z])+/.test(password)) {
        this.errors.push("Password should have at least 1 upper case letter");
        return false;
      }
      if (/([\@#$\&\*]){1,}/.test(password)) {
        this.errors.push("Password contains illegal characters @#$&*");
        return false;
      }
      if (!/([\!%\^&\*\(\)_\-\.])+/.test(password)) {
        this.errors.push("Password should have at least 1 symbol <>.!%^()_-");
        return false;
      }
      return true;
    }
  },
  watch: {
    "model.password": function() {
      this.passwordValidator();
    }
  }
});
