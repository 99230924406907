
import vue from "vue";
import SpinnerComponent from "@/components/custom/spinnerLoaderComponent.vue";
import HeaderComponent from "@/components/ui/HeaderComponent.vue";
import CopyrightComponent from "@/components/custom/CopyrightComponent.vue";
import { notification } from "onsenui";
import { SecretService, SecretService2 } from "@/services/secret.service";
const apple = new SecretService();
const android = new SecretService2();
export default vue.extend({
  components: {
    SpinnerComponent,
    HeaderComponent,
    CopyrightComponent,
  },
  data() {
    return {
      loadingText: "",
      isLoading: false,
      isValidating: false,
      pbxModel: {
        name: "",
        user: "",
      },
      model: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    scanQrCode() {
      // @ts-ignore
      softphone.openQrCodeScanner(this.processQRCode);
    },
    processQRCode(qrcode: any) {
      const code = qrcode.toString();
      const els = code.split(":");
      this.model.username = els[1];
      this.model.password = els[2].substr(0, els[2].lastIndexOf("@"));
      this.pbxModel.name = this.model.username.substr(
        0,
        this.model.username.indexOf("@")
      );
      this.pbxModel.user = this.model.username.substr(
        this.model.username.indexOf("@") + 1
      );
    },
    signIn() {
      //@APPLE
      if (
        apple.user1 == this.model.username ||
        apple.user2 == this.model.username
      ) {
        if (this.model.password == apple.password) {
          this.model.username = this.model.username.substr(
            0,
            this.model.username.indexOf("@")
          );
          const url = `csc:${this.model.username}:${this.model.password}@APPLE`;
          let link = document.createElement("a");
          link.setAttribute("href", url);
          link.click();
        } else {
          notification.toast("Incorrect username or password", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000,
          });
        }
      } else if (android.user1 == this.model.username) {
        if (this.model.password == android.password) {
          this.model.username = this.model.username.substr(
            0,
            this.model.username.indexOf("@")
          );
          const url = `csc:${this.model.username}:${this.model.password}@GOOGLE`;
          let link = document.createElement("a");
          link.setAttribute("href", url);
          link.click();
        } else {
          notification.toast("Incorrect username or password", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000,
          });
        }
      } else {
        const url = `csc:${this.model.username}:${this.model.password}@pbxservice.cloud`;
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.click();
      }
    },
    goBack() {
      this.$router.push("welcome");
    },
  },
  watch: {
    "model.password": function () {
      /** LAME!! */
      if (this.model.username && this.model.password) {
        document.querySelector(".btn-sign-in").classList.remove("btn-dark");
      } else {
        document.querySelector(".btn-sign-in").classList.add("btn-dark");
      }
    },
  },
});
