import Vue from "vue";
import VueRouter from "vue-router";
import Registration from "@/views/signup/Registration.vue";
import Payments from "@/views/signup/CardInformation.vue";
import SetupPBX from "@/views/signup/PBXSetup.vue";
import Welcome from "@/views/signup/Welcome.vue";
import StartApp from "@/views/signup/StartApp.vue";
import SignIn from "@/views/SignIn.vue";
import UserLogin from "@/views/signup/UserLogin.vue";
import Intro from "@/views/Intro.vue";
import Otp from "@/views/signup/Otp.vue";
import DesktopSignIn from "@/views/desktop/DesktopSignIn.vue";
import ResetPassword from "@/views/signup/ResetPassword.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Intro to products",
      component: Welcome,
      beforeEnter: (to, from, next) => {
        const device = to.query.device || "";
        if (
          device &&
          device
            .toString()
            .toLowerCase()
            .includes("chrome")
        ) {
          next({ path: "/desktop/sign-in" });
        } else {
          next();
        }
      }
    },
    {
      path: "/desktop/sign-in",
      name: "desktop-sign-in",
      component: DesktopSignIn
    },
    {
      path: "/welcome",
      name: "welcome",
      component: Welcome
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ResetPassword
    },
    {
      path: "/sign-in",
      name: "sign in",
      component: SignIn
    },
    {
      path: "/login",
      name: "user login",
      component: UserLogin
    },
    {
      path: "/register",
      name: "register",
      component: Registration
    },
    {
      path: "/otp/:mobileNumber",
      name: "otp",
      component: Otp
    },
    {
      path: "/payments",
      name: "payments",
      component: Payments
    },
    {
      path: "/setup-pbx",
      name: "setup-pbx",
      component: SetupPBX
    },
    {
      path: "/start-app/:username/:password/:code",
      name: "start-app",
      component: StartApp
    }
  ]
});

export default router;
