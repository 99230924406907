import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import axios, { AxiosStatic } from "axios";
import Vue from "vue";
import App from "./App.vue";
import VueOnsen from "vue-onsenui";
import router from "./router";
import store from "./store";
import VueTelInput from "vue-tel-input";
import { Logger } from "@MyTelnet/mytelnet-client-logging-library";

/** axios config */
axios.defaults.headers.common.Accept = "application/json";
axios.interceptors.request.use(
  config => {
    config.timeout = 60 * 5 * 1000; // 5 minutes
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
declare module "vue/types/vue" {
  interface Vue {
    $http: AxiosStatic;
  }
}
Vue.config.productionTip = false;
Vue.use(VueOnsen);
Vue.use(VueTelInput);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export const $http = axios;
export const $logger = Logger;
