export class Cookie {

  public static parseToken(token: string) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  public static set(key: string, value: string, expirationInDays: any) {
    if (expirationInDays != "max") {
      let d = new Date();
      d.setTime(d.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
      expirationInDays = "expires=" + d.toUTCString();
      document.cookie = `${key} = ${value};${expirationInDays};path=/`;
    } else {
      document.cookie =
        key +
        "=" +
        value +
        ";" +
        'expires=Fri, 31 Dec 9999 23:59:59 GMT"' +
        ";path=/";
    }
  }

  public static get(key: string) {
    let name = key + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  public static remove(name: string) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;";
  }

  public static removeAll() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(
          /=.*/,
          "=;expires=Thu, 01 Jan 1970 00:00:00 GMT" +
          new Date().toUTCString() +
          ";path=/"
        );
    });
  }
}
