export class SecretService {
  user1: string;
  user2: string;
  password: string;
  constructor() {
    this.user1 = "27753208@APPLE";
    this.user2 = "27753207@APPLE";
    this.password = "pq4j9W95";
  }
}


// csc:466453207:pq4j9W95@GOOGLE
export class SecretService2 {
  user1: string;
  user2: string;
  password: string;
  constructor() {
    this.user1 = "466453207@GOOGLE";
    this.password = "pq4j9W95";
  }
}
