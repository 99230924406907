
import vue from "vue";
import HeaderComponent from "@/components/ui/HeaderComponent.vue";
import SpinnerComponent from "@/components/custom/spinnerLoaderComponent.vue";
import CopyrightComponent from "@/components/custom/CopyrightComponent.vue";
import { AuthService, LoginModel } from "@/services/auth.service";
import { Cookie } from "@/common/cookie";
import { CampaignService } from "@/services/campaign.service";
import { ErrorMeta } from "@/models/ErrorMeta";
import { $logger } from "@/main";
import { SeverityTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/severity.lookup";
import { LoggingTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/logging.lookup";
export default vue.extend({
  components: {
    HeaderComponent,
    SpinnerComponent,
    CopyrightComponent
  },
  data() {
    return {
      model: {} as LoginModel,
      isLoading: false,
      loadingText: "Logging user in",
      error: "",
      authType: null
    };
  },
  methods: {
    async loginUser() {
      this.isLoading = true;
      try {
        this.model.email = this.model.email.trim();
        const result = await new AuthService().login(this.model);
        if (result.data && result.status === 200) {
          Cookie.remove("AUTH");
          Cookie.parseToken(result.data.token);
          Cookie.set("AUTH", result.data.token, 1);
          const campaignResult = await new CampaignService().get();
          await this.getCampaignAuth();
          if (campaignResult.data && campaignResult.status == 200) {
            const step = parseInt(campaignResult.data.result);
            if (step === 1) {
              const result = await new AuthService().getCurrentUser();
              if (result.status === 200 && result.data) {
                const currentUser = result.data;
                await new AuthService().sendOTP();
                await this.$router.push("otp/" + currentUser.phoneNumber);
              }
            } else if (step === 2 && this.authType && this.authType === 2) {
              /* verified phone and email - push to payments */
              await this.$router.push("payments");
            } else if (step === 3) {
              /* card info was captured - go pbx configuration */
              await this.$router.push("setup-pbx");
            } else {
              /* user has completed sign up*/
              (this as any).$ons.notification.toast(
                "You have already completed your sign up",
                {
                  animation: "ascend",
                  buttonLabel: "Ok",
                  timeout: 5000
                }
              );
              await this.$router.push("sign-in");
            }
          }
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const msg =
          e.response.data.message || "Login failed for supplied credentials";
        (this as any).$ons.notification.toast(msg, {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "loginUser()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    },
    async getCampaignAuth() {
      try {
        let result = await new AuthService().getCampaignAuth();
        if (result.status === 200) {
          this.authType = result.data.result;
        }
      } catch (e) {
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "getCampaignAuth()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    }
  },
  watch: {
    "model.email": function() {
      const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      this.model.email = this.model.email.trim();
      if (!re.test(this.model.email)) {
        this.error = "Invalid email format";
      } else {
        this.error = "";
      }
    }
  }
});
