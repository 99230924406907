
import vue from "vue";
import HeaderComponent from "@/components/ui/HeaderComponent.vue";
import SpinnerComponent from "@/components/custom/spinnerLoaderComponent.vue";
import CopyrightComponent from "@/components/custom/CopyrightComponent.vue";
import { AuthService } from "@/services/auth.service";
import { CampaignService } from "@/services/campaign.service";
import * as onsen from "onsenui";
import { ErrorMeta } from "@/models/ErrorMeta";
import { $logger } from "@/main";
import { SeverityTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/severity.lookup";
import { LoggingTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/logging.lookup";
import { Cookie } from "@/common/cookie";
export default vue.extend({
  data() {
    return {
      ons: onsen,
      modalVisible: false,
      authType: null,
      model: {
        phoneNumber: "",
        oneTimePin: ""
      },
      isLoading: false,
      loadingText: "Verifying otp",
      telProps: {
        mode: "international",
        defaultCountry: "ZA",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Phone Number",
        required: true,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: [],
        onlyCountries: ["BW", "LS", "NA", "ZA", "MW", "MZ", "SZ", "ZM", "ZW"],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 20,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    };
  },
  mounted() {
    this.model.phoneNumber = this.$route.params["mobileNumber"];
    this.getRefreshedToken();
  },
  components: {
    spinner: SpinnerComponent,
    copyright: CopyrightComponent,
    "header-component": HeaderComponent
  },
  methods: {
    async confirmOneTimePin() {
      try {
        this.isLoading = true;
        this.loadingText = "Confirming OTP";
        const result = await new AuthService().verifyOTP(this.model.oneTimePin);
        if (result.data && result.status === 200) {
          (this as any).$ons.notification.toast("Phone number verified", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
          await this.getCampaignAuth();
          /* authType. 1 == mobile verification, 2 == payment */
          // mobile verification on the app is a MUST
          await new CampaignService().post(2);
          if (this.authType && this.authType === 2) {
            await this.$router.push("/payments");
          } else {
            await new CampaignService().post(3);
            await this.$router.push("/setup-pbx");
          }
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data;
        if (error.message) {
          (this as any).$ons.notification.toast(`ERROR: ${error.message}`, {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        } else {
          (this as any).$ons.notification.toast(
            "ERROR: Phone verification failed",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
        }
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "confirmOneTimePin()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    },
    async resentOTP() {
      try {
        this.isLoading = true;
        this.loadingText = "Sending OTP";
        const result = await new AuthService().sendOTP();
        if (result.status === 200) {
          (this as any).$ons.notification.toast("New OTP Sent!", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        (this as any).$ons.notification.toast("ERROR: Failed to send OTP", {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "resentOTP()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    },
    async updatePhoneNumber() {
      try {
        this.modalVisible = false;
        this.isLoading = true;
        this.loadingText = "Updating number";
        this.model.phoneNumber = this.model.phoneNumber.replace(/\s/g, "");
        const result = await new AuthService().updatePhone(
          this.model.phoneNumber
        );
        if (result.status === 200) {
          (this as any).$ons.notification.toast("New OTP Sent!", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        (this as any).$ons.notification.toast("ERROR: Failed to send OTP", {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "updatePhoneNumber()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    },
    async getCampaignAuth() {
      try {
        let result = await new AuthService().getCampaignAuth();
        if (result.status === 200) {
          this.authType = result.data.result;
        }
      } catch (e) {
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "getCampaignAuth()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    },
    async getRefreshedToken() {
      try {
        const response = await new AuthService().refreshToken();
        console.log(response);
        if (response.status == 200) {
          Cookie.remove("AUTH");
          Cookie.parseToken(response.data.token);
          Cookie.set("AUTH", response.data.token, 1);
        }
      } catch (e) {
        console.log(e);
        (this as any).$ons.notification.toast("Failed to refresh token", {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "getRefreshedToken()",
          error: e.response.data
        } as ErrorMeta;
        $logger.log(
          e,
          JSON.stringify(meta),
          SeverityTypeLookUp.low,
          LoggingTypeLookUp.error
        );
      }
    }
  }
});
