
import Vue from "vue";
import SpinnerLoader from "@/components/custom/spinnerLoaderComponent.vue";
export default Vue.extend({
  components: {
    SpinnerLoader
  },
  data() {
    return {
      csc: "",
      isLoading: true
    };
  },
  mounted() {
    this.csc = this.$route.params.authcsc;
    this.signIn(this.$route.params.authcsc);
  },
  methods: {
    signIn(url: string) {
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.click();
    }
  }
});
