
import Vue from "vue";
import CopyrightComponent from "@/components/custom/CopyrightComponent.vue";
export default Vue.extend({
  components: {
    "copy-right": CopyrightComponent
  },
  methods: {
    async signUp() {
      await this.$router.push("register");
    },
    async signIn() {
      await this.$router.push("sign-in");
    },
    async forgotPassword() {
      await this.$router.push("forgot-password");
    }
  }
});
