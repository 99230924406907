import { $http } from "@/main";
import { Cookie } from "@/common/cookie";

export class BillingService {
  private readonly token: string = "";
  constructor() {
    $http.defaults.baseURL = process.env.VUE_APP_BILLING_HOST;
    this.token = Cookie.get("AUTH");
  }
  public async getCampaignProducts() {
    return $http.get(`/customer/campaign/products`, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async getCampaignDetails() {
    return $http.get(`/campaign/details`, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async customerHasDeal() {
    return $http.get(`/signup/has-deal`, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async tokenizeCard(card: IPaymentCard) {
    return $http.post(`/mobile/payment/token`, card, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async followUp(model: IFollowPayment) {
    return $http.post(`/mobile/payment/follow-up`, model, {
      headers: { Authorization: `Bearer ${this.token}` }
    });
  }
  public async createFusionPBXtoBPOAssociation() {
    return $http.post(
      `/voice/product/link`,
      {},
      {
        headers: { Authorization: `Bearer ${this.token}` }
      }
    );
  }
}

export interface IPaymentCard {
  firstName: string;
  lastName: string;
  cardNumber: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  email: string;
  cvv: string;
}
export interface IFollowPayment {
  payRequestId: string;
  cardHolder: string;
  tmsid?: string;
}
