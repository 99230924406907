
import Vue from "vue";
import HeaderComponent from "@/components/ui/HeaderComponent.vue";
import SpinnerComponent from "@/components/custom/spinnerLoaderComponent.vue";
import CopyrightComponent from "@/components/custom/CopyrightComponent.vue";
import { CreditCardModel } from "@/models/SignUpModels";
import io from "socket.io-client";
import {
  BillingService,
  IFollowPayment,
  IPaymentCard
} from "@/services/billing.service";
import { CampaignService } from "@/services/campaign.service";
import { AuthService } from "@/services/auth.service";
import { Cookie } from "@/common/cookie";
import { ErrorMeta } from "@/models/ErrorMeta";
import { $logger } from "@/main";
import { SeverityTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/severity.lookup";
import { LoggingTypeLookUp } from "@MyTelnet/mytelnet-client-logging-library/dist/lookups/logging.lookup";

let socket: SocketIOClient.Socket;
export default Vue.extend({
  components: {
    HeaderComponent,
    SpinnerComponent,
    CopyrightComponent
  },
  data() {
    return {
      model: {} as CreditCardModel,
      threeDSecure: "",
      socketMessages: "Socket offline",
      isLoading: false,
      is3DSecure: false,
      loadingText: "Processing payment"
    };
  },
  async mounted() {
    await this.getRefreshedToken();
  },
  methods: {
    connectToSocket(payRequestId: string) {
      this.socketMessages = "Connecting to socket";
      socket = io("https://sockets.core.mytelnet.co.za/", {
        query: {
          PAYREQUESTID: payRequestId
        }
      });
      socket.on("message", (e: any) => {
        this.socketMessages = e;
      });
      socket.on("joined", (content: any) => {
        this.socketMessages = content;
      });
      socket.on("complete", async (payload: any) => {
        this.socketMessages = payload.message;
        this.isLoading = true;
        this.threeDSecure = "";
        this.loadingText = "Completing payment";
        await this.followUpRequest(payload.payrequestId);
      });
      socket.on("error", (err: any) => {
        (this as any).$ons.notification.toast(err, {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
      });
    },
    async saveCardInformation() {
      try {
        if (!this.model.cardNumber || !this.model.securityCode) {
          (this as any).$ons.notification.toast(
            "Card or CVV number is invalid",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
          this.isLoading = false;
          return;
        }
        if (this.model.securityCode.length !== 3) {
          (this as any).$ons.notification.toast(
            "CVV must be 3 numbers at the back of your card",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
          this.isLoading = false;
          return;
        }

        this.isLoading = true;
        const cookie = Cookie.get("AUTH");
        const token = Cookie.parseToken(cookie);
        const emailAddress = token.username.toString();
        this.model.email = emailAddress;
        if (emailAddress && emailAddress.includes("avon@mytelnet.co.za")) {
          this.model.email = "Avon@mytelnet.co.za";
        }
        const cardDetails: IPaymentCard = {
          firstName: this.model.cardHolder.split(" ")[0],
          lastName: this.model.cardHolder.split(" ")[1],
          cardNumber: this.model.cardNumber,
          cardExpirationMonth: this.model.cardExpiration.split("/")[0],
          cardExpirationYear: this.model.cardExpiration.split("/")[1],
          email: this.model.email,
          cvv: this.model.securityCode
        };
        if (!cardDetails.lastName) {
          cardDetails.lastName = cardDetails.firstName;
        }
        const result = await new BillingService().tokenizeCard(cardDetails);

        if (result.data && result.status === 200) {
          window.sessionStorage.setItem(
            "PAYREQUESTID",
            result.data.payRequestId
          );
          if (
            result.data.paymentCompleted &&
            result.data.resultCode == "990017"
          ) {
            // card tokenized and card updated
            (this as any).$ons.notification.toast("Payment Completed!", {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            });
            await new CampaignService().post(3);
            await this.$router.push("setup-pbx");
            // await this.followUpRequest(result.data.payRequestId);
          } else {
            this.connectToSocket(result.data.payRequestId);
            this.threeDSecure = result.data;
            this.is3DSecure = true;
          }
        }
        this.isLoading = false;
      } catch (exception) {
        this.isLoading = false;
        this.is3DSecure = false;
        const errdata = exception.response.data;
        if (errdata && errdata.code && errdata.description) {
          const reason = `${errdata.code} : ${errdata.description}`;
          (this as any).$ons.notification.toast(reason, {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        } else if (exception.response.data.message) {
          (this as any).$ons.notification.toast(
            exception.response.data.message || "Payment failed to process",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
        } else {
          (this as any).$ons.notification.toast(
            exception.message || "Payment failed to process",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
        }
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "saveCardInformation()",
          error: exception.response.data
        } as ErrorMeta;
        $logger.log(
          exception,
          JSON.stringify(meta),
          SeverityTypeLookUp.high,
          LoggingTypeLookUp.error
        );
      }
    },
    async followUpRequest(payId?: string) {
      const payRequestId =
        payId || window.sessionStorage.getItem("PAYREQUESTID");
      try {
        const cookie = Cookie.get("AUTH");
        const token = Cookie.parseToken(cookie);
        const follow: IFollowPayment = {
          cardHolder: this.model.cardHolder,
          payRequestId: payRequestId,
          tmsid: token.customerId
        };
        const result = await new BillingService().followUp(follow);
        socket.disconnect();
        this.socketMessages = "WS closed";
        this.isLoading = false;
        this.threeDSecure = "";
        this.is3DSecure = false;
        if (result && result.data) {
          (this as any).$ons.notification.toast("Payment Completed!", {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
          await new CampaignService().post(3);
          await this.$router.push("setup-pbx");
        } else {
          (this as any).$ons.notification.toast(
            "Payment tokenization failed!",
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
        }
        await this.$router.push("setup-pbx");
      } catch (exception) {
        this.isLoading = false;
        const errdata = exception.response.data;
        if (errdata && errdata.code && errdata.description) {
          const reason = `${errdata.code} : ${errdata.description}`;
          (this as any).$ons.notification.toast(reason, {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        } else if (exception.response.data.message) {
          (this as any).$ons.notification.toast(
            exception.response.data.message,
            {
              animation: "ascend",
              buttonLabel: "Ok",
              timeout: 5000
            }
          );
        } else {
          (this as any).$ons.notification.toast(exception.message, {
            animation: "ascend",
            buttonLabel: "Ok",
            timeout: 5000
          });
        }
        socket.disconnect();
        this.socketMessages = "WS closed";
        this.threeDSecure = "";
        this.is3DSecure = false;
        const meta = {
          platform: "mobile-sign-up",
          url: window.location.href,
          function: "followUpRequest()",
          error: exception.response.data
        } as ErrorMeta;
        $logger.log(
          exception,
          JSON.stringify(meta),
          SeverityTypeLookUp.high,
          LoggingTypeLookUp.error
        );
        await this.$router.push("setup-pbx");
      }
    },
    formatCCExp(value: any) {
      const v = value.replace(/\D/gi, "").substring(0, 4);
      const matches = v.match(/([0-1][0-9])([1-9][0-9])/);
      if (matches) {
        let month = 0;
        let year = 0;
        try {
          month = Number.parseInt(matches[1], 10);
          year = Number.parseInt(matches[2], 10);
        } catch (xe) {
          return value;
        }
        return `${month < 10 ? "0" : ""}${month}/${year}`;
      }
      return v;
    },
    formatCCCVV(value: any) {
      const v = value.replace(/\D/gi, "").substring(0, 3);
      return v;
    },
    formatCC(value: any) {
      const v = value.replace(/\D/gi, "").substring(0, 16);
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || "";
      const parts = [];

      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }

      if (parts.length) {
        return parts.join(" ");
      }
      return v;
    },
    formatCreditCardNumber() {
      this.model.cardNumber = this.formatCC(this.model.cardNumber);
    },
    formatSecurityCode() {
      this.model.securityCode = this.formatCCCVV(this.model.securityCode);
    },
    formatcardExpiration() {
      this.model.cardExpiration = this.formatCCExp(this.model.cardExpiration);
    },
    async getRefreshedToken() {
      try {
        const response = await new AuthService().refreshToken();
        if (response.status == 200) {
          Cookie.remove("AUTH");
          Cookie.parseToken(response.data.token);
          Cookie.set("AUTH", response.data.token, 1);
        }
      } catch (e) {
        (this as any).$ons.notification.toast("Failed to refresh token", {
          animation: "ascend",
          buttonLabel: "Ok",
          timeout: 5000
        });
      }
    }
  },
  watch: {
    "model.cardExpiration": function() {
      this.formatcardExpiration();
    },
    "model.cardNumber": function() {
      this.formatCreditCardNumber();
    },
    "model.securityCode": function() {
      this.formatSecurityCode();
    }
  }
});
